import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { manifestResolver } from './resolvers/manifest.resolver';
import { ManifestComponent } from './views/manifest/manifest.component';
import { WelcomeComponent } from './views/welcome/welcome.component';

const routes: Routes = [
    {
        component: WelcomeComponent,
        path: '',
    },
    {
        component: ManifestComponent,
        path: 'manifest/:token',
        resolve: {
            manifest: manifestResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {
    //
}
