import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class OptionsInterceptor implements HttpInterceptor {
    public intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        if (req.headers.has('authorization')) {
            return next.handle(req);
        }

        const authToken = btoa(environment.bumper.id + ':' + environment.bumper.secret);

        const authReq = req.clone({
            headers: req.headers.set('authorization', authToken),
        });

        return next.handle(authReq);
    }
}
