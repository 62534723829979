import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Sample } from '../interfaces/sample';
import { AssetScalePipe } from './asset-scale.pipe';

@Injectable({ providedIn: 'root' })
@Pipe({
    name: 'assetScaleHeight',
})
export class AssetScaleHeightPipe implements PipeTransform {

    public constructor(
        private readonly assetScale: AssetScalePipe,
    ) {
        //
    }

    public transform(sample: Sample, height: number, maxWidth: number): number {
        const width = this.assetScale.transform(sample, 'width');
        let scale = 1;

        if (width > maxWidth) {
            scale = maxWidth / width;
        }

        if (scale === 1) {
            return height;
        }

        return Math.round(height * scale);
    }

}
