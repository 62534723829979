import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BaseUrlInterceptor } from './interceptors/base-url-interceptor';
import { OptionsInterceptor } from './interceptors/options-interceptor';
import { WelcomeComponent } from './views/welcome/welcome.component';
import { ManifestComponent } from './views/manifest/manifest.component';
import { BypassSecurityPipe } from './pipes/bypass-security.pipe';
import { AssetScalePipe } from './pipes/asset-scale.pipe';
import { ScaleMarkupPipe } from './pipes/scale-markup.pipe';
import { InjectStylesPipe } from './pipes/inject-styles.pipe';
import { WrapScalePipe } from './pipes/wrap-scale.pipe';
import { AssetDisplayPipe } from './pipes/asset-display.pipe';
import { AssetScaleHeightPipe } from './pipes/asset-scale-height.pipe';
import { ApprovalDialogComponent } from './dialogs/approval-dialog/approval-dialog.component';
import { RequestChangesDialogComponent } from './dialogs/request-changes-dialog/request-changes-dialog.component';
import { SuccessDialogComponent } from './dialogs/success-dialog/success-dialog.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        WelcomeComponent,
        ManifestComponent,
        BypassSecurityPipe,
        AssetScalePipe,
        ScaleMarkupPipe,
        InjectStylesPipe,
        WrapScalePipe,
        AssetDisplayPipe,
        AssetScaleHeightPipe,
        ApprovalDialogComponent,
        RequestChangesDialogComponent,
        SuccessDialogComponent,
        ErrorDialogComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatButtonModule,
        HttpClientModule,
        MatDialogModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OptionsInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
