<h1 mat-dialog-title>Request Changes</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <p>Fill the form below to request changes to the campaign assets.</p>
        <div class="form-group mb-3">
            <label>Enter your email</label>
            <input type="text" formControlName="email" class="form-control">
        </div>

        <div class="form-group">
            <label>Comments</label>
            <textarea class="form-control" formControlName="change"></textarea>
        </div>

        <small class="mt-2 d-block">
            Please enter a comment about the changes you wish to see. Please be as specific as possible.
        </small>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" [disabled]="form.invalid" [mat-dialog-close]="form.value">Submit</button>
</mat-dialog-actions>
