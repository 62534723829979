import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResolveFn, Router } from '@angular/router';
import { catchError, NEVER } from 'rxjs';
import { ErrorDialogComponent } from '../dialogs/error-dialog/error-dialog.component';
import { ManifestResponse } from '../interfaces/manifest-response';
import { ManifestService } from '../services/manifest.service';

export const manifestResolver: ResolveFn<ManifestResponse> = (route) => {
    const token = route.paramMap.get('token');
    if (!token) {
        console.error('Cannot resolve without a token in the url');
        return NEVER;
    }

    const matDialog = inject(MatDialog);
    const router = inject(Router);

    return inject(ManifestService).manifest(token).pipe(
        catchError((error) => {
            const message = error.error?.error || 'An unknown error has occurred';
            matDialog.open(ErrorDialogComponent, {
                data: 'Unable to resolve the campaign based on the token. ' + message,
                width: '500px',
            });

            router.navigate(['/'], { queryParams: { token } });
            return NEVER;
        }),
    );
};
