<h1 mat-dialog-title>Approval</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <p>Approving the assets will notify your performance manager of the approval, and will move ahead with the campaign.</p>
        <div class="form-group">
            <label>Enter Your Email</label>
            <input type="text" formControlName="email" class="form-control">
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="form.value">Confirm Approval</button>
</mat-dialog-actions>
