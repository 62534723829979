import { Pipe, PipeTransform } from '@angular/core';
import { AssetScalesService } from '../services/asset-scales.service';

@Pipe({
    name: 'injectStyles',
})
export class InjectStylesPipe implements PipeTransform {

    public constructor(
        private readonly scales: AssetScalesService,
    ) {
        //
    }

    public transform(value: string, type: string): string {
        const style =
            '<script src="https://kit.fontawesome.com/4f7394a510.js" crossorigin="anonymous"></script>' +
            '<style> ' +
            '* { box-sizing: border-box; } ' +
            'body, html { height: 100%; margin: 0; padding: 0; } ' +
            '.template-wrapper { transform: scale(' + this.scales.scale(type) + '); transform-origin: 0 0; } ' +
            '* { font-family: sans-serif; }' +
            '.sms-content { display: none; }' +
            '</style>' + "\n";

        return style + value;
    }

}
