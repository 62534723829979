import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ManifestResponse } from '../interfaces/manifest-response';

@Injectable({
    providedIn: 'root',
})
export class ManifestService {

    public constructor(
        private readonly http: HttpClient,
    ) {
        //
    }

    public approve(token: string, params: object): Observable<void> {
        return this.http.post<void>('/api/manifest/' + token + '/approve', params);
    }

    public deny(token: string, params: object): Observable<void> {
        return this.http.post<void>('/api/manifest/' + token + '/deny', params);
    }

    public manifest(token: string): Observable<ManifestResponse> {
        return this.http.get<ManifestResponse>('/api/manifest/' + token).pipe(
            map((res) => {
                res.samples = res.samples.sort((a, b) => {
                    if (a.type === b.type) {
                        return 0;
                    }

                    return a.type > b.type ? 1 : -1;
                });

                return res;
            }),
        );
    }
}
