import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Sample } from '../interfaces/sample';
import { AssetScalesService } from '../services/asset-scales.service';

@Injectable({ providedIn: 'root' })
@Pipe({
    name: 'assetScale',
})
export class AssetScalePipe implements PipeTransform {

    public constructor(
        private readonly scales: AssetScalesService,
    ) {
        //
    }

    public transform(sample: Sample, dimension: 'width' | 'height'): number {
        return Math.round(sample[dimension] * this.scales.scale(sample.type));
    }

}
