import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
    public readonly form = this.forms.group({
        token: ['', Validators.required],
    });

    public constructor(
        private readonly forms: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {
        //
    }

    public ngOnInit(): void {
        this.route.queryParamMap.pipe(first()).subscribe((q) => {
            this.form.get('token')!.setValue(q.get('token') || '');
        });
    }

    public onSubmit(): void {
        this.router.navigate(['manifest', this.form.value.token]);
    }
}
