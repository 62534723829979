import { Pipe, PipeTransform } from '@angular/core';
import { Sample } from '../interfaces/sample';
import { AssetScalePipe } from './asset-scale.pipe';

@Pipe({
    name: 'wrapScale',
})
export class WrapScalePipe implements PipeTransform {

    public constructor(
        private readonly assetScale: AssetScalePipe,
    ) {
        //
    }

    public transform(sample: Sample, maxWidth: number): string {
        const width = this.assetScale.transform(sample, 'width');
        let scale = 1;

        if (width > maxWidth) {
            scale = maxWidth / width;
        }

        return 'scale(' + scale + ')';
    }

}
