import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'assetDisplay',
})
export class AssetDisplayPipe implements PipeTransform {
    public readonly mapping: { [property: string]: string } = {
        purl: 'Landing Page',
        postcard_front: 'Postcard (Front)',
        postcard_back: 'Postcard (Back)',
    };

    public transform(type: string): string {
        if (Object.prototype.hasOwnProperty.call(this.mapping, type)) {
            return this.mapping[type];
        }

        return type;
    }

}
