<div class="d-flex align-items-center justify-content-center p-3">
    <form [formGroup]="form" (submit)="onSubmit()">
        <div class="form-group mb-2">
            <label>Enter Manifest Token</label>
            <input type="text" formControlName="token" class="form-control">
        </div>

        <button [disabled]="form.invalid" mat-flat-button color="primary">Submit</button>
    </form>
</div>
