import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AssetScalesService {
    public scale(type: string): number {
        if (type.indexOf('postcard_') === 0) {
            return 0.25;
        }

        return 1;
    }
}
