import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'bypassSecurity',
})
export class BypassSecurityPipe implements PipeTransform {

    public constructor(
        private readonly sanitizer: DomSanitizer,
    ) {
        //
    }

    public transform(markup: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(markup);
    }

}
