import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * General purpose error dialog component
 */
@Component({
    selector: 'app-error-dialog',
    styleUrls: ['./error-dialog.component.scss'],
    templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent {

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: string,
    ) {
        //
    }
}
